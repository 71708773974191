import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CreateAgentForm from '../components/CreateAgentForm/CreateAgentForm';
import AgentCard from '../components/AgentCard/AgentCard';
import Notification from '../components/Notification/Notification';
import { getAgents, type Agent } from '../store/agents';
import '../styles/Agents.css';

interface NotificationState {
  show: boolean;
  message: string;
  type: 'success' | 'pending';
}

const Agents = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [notification, setNotification] = useState<NotificationState>({
    show: false,
    message: '',
    type: 'success'
  });

  useEffect(() => {
    const loadAgents = () => {
      const userAgents = getAgents();
      setAgents(userAgents);
    };

    loadAgents();
    window.addEventListener('storage', loadAgents);
    return () => window.removeEventListener('storage', loadAgents);
  }, []);

  const handleAgentCreated = () => {
    setShowCreateForm(false);
    setAgents(getAgents());
    setNotification({
      show: true,
      message: 'Agent created successfully! Your agent is now under review.',
      type: 'pending'
    });
  };

  const handleCloseNotification = () => {
    setNotification(prev => ({ ...prev, show: false }));
  };

  return (
    <div className="agents-wrapper">
      <div className="agents-container">
        <Link to="/" className="back-link">← Back</Link>
        
        <div className="agents-header">
          <h1>Arc Agents</h1>
          <p className="subtitle">create your own ai agent powered by arc</p>
          <p className="description">Arc Agents will randomly choose one agent from the pool of agents to bring to life via random.org</p>
        </div>

        <div className="create-section">
          <button 
            className="create-agent-button"
            onClick={() => setShowCreateForm(true)}
          >
            Create New Agent
          </button>
        </div>

        {agents.length > 0 && (
          <div className="my-agents-section">
            <div className="section-header">
              <h2>My Agents</h2>
              <div className="status-legend">
                <span>Under Review</span>
                <span>Active</span>
              </div>
            </div>
            <div className="agents-grid">
              {agents.map(agent => (
                <AgentCard
                  key={agent.id}
                  name={agent.name}
                  role={agent.role}
                  personality={agent.personality}
                  interactions={agent.interactions}
                  status={agent.status}
                />
              ))}
            </div>
          </div>
        )}

        {showCreateForm && (
          <CreateAgentForm
            onClose={() => setShowCreateForm(false)}
            onSuccess={handleAgentCreated}
          />
        )}

        {notification.show && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={handleCloseNotification}
          />
        )}
      </div>
    </div>
  );
};

export default Agents;
