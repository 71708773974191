import * as THREE from 'three';

export function createRobot() {
  const robotGroup = new THREE.Group();

  // Core/Body
  const core = new THREE.Mesh(
    new THREE.CylinderGeometry(0.2, 0.3, 0.8, 8),
    new THREE.MeshStandardMaterial({ 
      color: 0x2196f3,
      metalness: 0.8,
      roughness: 0.2,
      emissive: 0x0d47a1,
      emissiveIntensity: 0.2
    })
  );
  core.position.y = 0.6;
  robotGroup.add(core);

  // Head
  const head = new THREE.Mesh(
    new THREE.BoxGeometry(0.3, 0.25, 0.25),
    new THREE.MeshStandardMaterial({ 
      color: 0x1976d2,
      metalness: 0.8,
      roughness: 0.2
    })
  );
  head.position.y = 1.1;
  robotGroup.add(head);

  // Eyes
  const eyeGeometry = new THREE.SphereGeometry(0.05, 16, 16);
  const eyeMaterial = new THREE.MeshStandardMaterial({
    color: 0x00ff00,
    emissive: 0x00ff00,
    emissiveIntensity: 0.8
  });

  const leftEye = new THREE.Mesh(eyeGeometry, eyeMaterial);
  leftEye.position.set(-0.08, 1.1, 0.13);
  robotGroup.add(leftEye);

  const rightEye = new THREE.Mesh(eyeGeometry, eyeMaterial);
  rightEye.position.set(0.08, 1.1, 0.13);
  robotGroup.add(rightEye);

  // Antenna
  const antenna = new THREE.Mesh(
    new THREE.ConeGeometry(0.05, 0.2, 8),
    new THREE.MeshStandardMaterial({ 
      color: 0x1565c0,
      metalness: 0.8,
      roughness: 0.2
    })
  );
  antenna.position.y = 1.3;
  robotGroup.add(antenna);

  // Arms
  const armGeometry = new THREE.BoxGeometry(0.1, 0.4, 0.1);
  const armMaterial = new THREE.MeshStandardMaterial({
    color: 0x1565c0,
    metalness: 0.8,
    roughness: 0.2
  });

  const leftArm = new THREE.Mesh(armGeometry, armMaterial);
  leftArm.position.set(-0.3, 0.7, 0);
  robotGroup.add(leftArm);

  const rightArm = new THREE.Mesh(armGeometry, armMaterial);
  rightArm.position.set(0.3, 0.7, 0);
  robotGroup.add(rightArm);

  // Hands
  const handGeometry = new THREE.SphereGeometry(0.06, 8, 8);
  const handMaterial = new THREE.MeshStandardMaterial({
    color: 0x0d47a1,
    metalness: 0.8,
    roughness: 0.2
  });

  const leftHand = new THREE.Mesh(handGeometry, handMaterial);
  leftHand.position.set(-0.3, 0.5, 0);
  robotGroup.add(leftHand);

  const rightHand = new THREE.Mesh(handGeometry, handMaterial);
  rightHand.position.set(0.3, 0.5, 0);
  robotGroup.add(rightHand);

  // Legs
  const legGeometry = new THREE.BoxGeometry(0.15, 0.4, 0.15);
  const legMaterial = new THREE.MeshStandardMaterial({
    color: 0x1565c0,
    metalness: 0.8,
    roughness: 0.2
  });

  const leftLeg = new THREE.Mesh(legGeometry, legMaterial);
  leftLeg.position.set(-0.15, 0.2, 0);
  robotGroup.add(leftLeg);

  const rightLeg = new THREE.Mesh(legGeometry, legMaterial);
  rightLeg.position.set(0.15, 0.2, 0);
  robotGroup.add(rightLeg);

  // Energy core ring
  const ringGeometry = new THREE.TorusGeometry(0.25, 0.02, 16, 32);
  const ringMaterial = new THREE.MeshStandardMaterial({
    color: 0x64b5f6,
    emissive: 0x64b5f6,
    emissiveIntensity: 0.5
  });
  const ring = new THREE.Mesh(ringGeometry, ringMaterial);
  ring.position.y = 0.6;
  ring.rotation.x = Math.PI / 2;
  robotGroup.add(ring);

  // Animation
  let time = 0;
  robotGroup.userData.animate = () => {
    time += 0.02;

    // Hover animation
    robotGroup.position.y = Math.sin(time) * 0.1;

    // Ring rotation
    ring.rotation.z = time;

    // Arm movement
    leftArm.rotation.x = Math.sin(time) * 0.2;
    rightArm.rotation.x = Math.sin(time + Math.PI) * 0.2;
    leftHand.position.z = Math.sin(time) * 0.1;
    rightHand.position.z = Math.sin(time + Math.PI) * 0.1;

    // Eye pulsing
    const eyeIntensity = 0.5 + Math.sin(time * 2) * 0.3;
    (leftEye.material as THREE.MeshStandardMaterial).emissiveIntensity = eyeIntensity;
    (rightEye.material as THREE.MeshStandardMaterial).emissiveIntensity = eyeIntensity;

    // Core pulsing
    (core.material as THREE.MeshStandardMaterial).emissiveIntensity = 0.2 + Math.sin(time * 3) * 0.1;
  };

  return robotGroup;
}
